import React from 'react'
import WeAreWorking from '../../components/common/WeAreWorking'

const Contact = () => {
  return (
    <div>
      <WeAreWorking/>
    </div>
  )
}

export default Contact
