import React from 'react';
import './styles.css';

const Header = () => {
  return (
    <header className='home-header'>
        <h2>Bienvenid@ a mi blog</h2>
        {/* <h1>
            <span>"<span>My Blog</span>"</span>
        </h1> */}
    </header>
  )
}

export default Header
