import React from 'react'

const VisitAccount = () => {
    return <div>
        <div hidden className="contador">
            <div id="sfc2xznnplm2jre2m6fs8eu7ntf96gmqb1k"></div>
            <script
                type="text/javascript"
                src="https://counter4.optistats.ovh/private/counter.js?c=2xznnplm2jre2m6fs8eu7ntf96gmqb1k&down=async"
                async
            ></script>
            <noscript
            ><a
                href="https://www.contadorvisitasgratis.com"
                title="contador de visitas html"
            ><img
                        src="https://counter4.optistats.ovh/private/contadorvisitasgratis.php?c=2xznnplm2jre2m6fs8eu7ntf96gmqb1k"
                        title="contador de visitas html"
                        alt="contador de visitas html" /></a
                ></noscript>
            <a
                href="https://www.contadorvisitasgratis.com"
                title="contador de visitas html"
            ><img
                    src="https://counter4.optistats.ovh/private/contadorvisitasgratis.php?c=2xznnplm2jre2m6fs8eu7ntf96gmqb1k"
                    border="0"
                    title="contador de visitas html"
                    alt="contador de visitas html"
                /></a>
        </div>
    </div>
}

export default VisitAccount
