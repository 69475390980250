import React from 'react'
import AboutMe from '../About me'
import './styles.css'

const Home = () => {
  return (
    <div>
      <AboutMe/>
    </div>
  )
}

export default Home