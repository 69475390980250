export const menu = [
  {
    orden: 2,
    description: "Mis trabajos",
    to: "my-works/"
  },
  {
    orden: 3,
    description: "Blog",
    to: "blog/"
  },/* 
    {
      orden: 2,
      description: "Sobre mí",
      to: "about-me/"
    }, */
 /*  {
    orden: 4,
    description: "Contacto",
    to: "contact/"
  }, */
  /* {
    orden: 5,
    description: "ES",
    to: "/",
    submenu: [
      {
        orden: 1,
        description: "ES",
        to: "/",
      },
      {
        orden: 2,
        description: "EN",
        to: "/",
      },
      {
        orden: 3,
        description: "IT",
        to: "/",
      },
    ]
  } */
]
